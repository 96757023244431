import {find, findKey, get} from 'lodash'
import {log} from '../../utils/monitoring'
import * as routersService from '../services/routers'
import * as routersWrapper from '../wrappers/routers'
import * as menusWrapper from '../wrappers/menus'
import * as applicationState from '../services/applicationState'

const removeMembersAreaPage = async ({editorSDK, appToken, pageId}) => {
    applicationState.setIsDeletingSinglePage(true)

    const {privateRouter, publicRouter} = await routersService.getMembersAreaRouters({editorSDK, appToken})
    const router = [privateRouter, publicRouter].find(r => find(r.config.patterns, p => get(p, 'appData.appPageId') === pageId))

    if (!router) {
        const errorMessage = 'Failed to find the router pattern in removeMembersAreaPage';
        log(errorMessage, {tags: {pageId}})
        throw new Error(errorMessage)
    }

    const patternData = find(router.config.patterns, p => get(p, 'appData.appPageId') === pageId)
    const pattern = findKey(router.config.patterns, p => get(p, 'appData.appPageId') === pageId)
    const page = router.pages.find(p => p.pageRoles.includes(patternData.page))

    if (!page) {
        const errorMessage = 'Failed to find the router pattern page in removeMembersAreaPage';
        log(errorMessage, {tags: {pageId, patternData: JSON.stringify(patternData)}})
        throw new Error(errorMessage)
    }

    const routerRef = await routersWrapper.getRouterRefByPrefix({editorSDK, appToken, prefix: router.prefix})

    if (!routerRef) {
        const errorMessage = 'Failed to get the routerRef in removeMembersAreaPage although the router is for sure installed';
        log(errorMessage, {tags: {pageId, patternData: JSON.stringify(patternData)}})
        throw new Error(errorMessage)
    }

    const removedInnerRoute = pattern.replace(/\/?(.*)/, '$1')

    delete router.config.patterns[pattern]

    await Promise.all([
        routersWrapper.updateRouterConfig({editorSDK, appToken, routerRef, config: router.config}),
        routersWrapper.removeRouterPage({editorSDK, appToken, routerRef, pageRef: page.pageRef}),
        menusWrapper.removePatternFromAllMenus({editorSDK, appToken, pattern: removedInnerRoute})
    ])
}

export {removeMembersAreaPage}
