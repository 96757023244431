import enforceSequentiality from '../enforceSequentiality'
import {toMonitored, log} from '../../utils/monitoring'
import {setMembersAreaApps, getMembersAreaApps} from '../services/applicationState'
import * as applicationState from '../applicationState'
import {addApplications} from '../platform-api/addApplications'
import {removeMembersAreaPage} from '../platform-api/removeMembersAreaPage'

interface Numbers {
    key: string
    default: number
}

interface Page {
    name: string
    shortDescription: string
    description: string
    iconURL: string
    origin: string
    isPrivate: boolean
    isNew: boolean
    screenshots: string[]
}

export interface IntegrationApplication {
    appDefinitionId: string
    pageId: string
    method?: string
    showInMemberMenu?: boolean
    showInLoginMenu?: boolean
    menuOrder?: number
    social?: boolean
    shouldNavigate?: boolean
    urlOverride?: string
    socialHome?: boolean
    loginMenuTitle?: string
    numbers?: Numbers
    visibleForRoles?: string[]
    page: Page
}

async function maybeAddApplications(applications: IntegrationApplication[], editorSDK, appToken = '') {
    return enforceSequentiality(async () => {
        const isReady = await applicationState.isApplicationReady(editorSDK, appToken)
        if (!isReady) {
            console.warn('Members Area installation was corrupted so the integrations pages will not be added')
            log('Skipping addApplications as the application is not ready and probably already deleted')
            return
        }
        const forceHorizontalLayout = false
        return toMonitored('editorApi.addApplications', () => addApplications({editorSDK, appToken, applications, forceHorizontalLayout}))
    })
}

function removePage(pageId: string, editorSDK, appToken = '') {
    return enforceSequentiality(() => toMonitored('editorApi.removeMembersAreaPage', () => removeMembersAreaPage({editorSDK, appToken, pageId})))
}

export const registerMembersAreaApps = (applications: IntegrationApplication[], verticalAppDefId: string) =>
    toMonitored('editorApi.registerMembersAreaApps', () => {
        const currentMembersAreaApps = getMembersAreaApps()
        setMembersAreaApps({
            ...currentMembersAreaApps,
            [verticalAppDefId]: applications
        })
    })

export const installRegisteredApps = (verticalAppDefId: string, editorSdk) =>
    toMonitored('editorApi.installRegisteredApps', async () => {
        const registeredMembersAreaApps = getMembersAreaApps()
        const verticalApps: IntegrationApplication[] = registeredMembersAreaApps?.[verticalAppDefId]

        if (verticalApps) {
            await maybeAddApplications(verticalApps, editorSdk)
        }
    })

export const handleVerticalDeletion = async (verticalAppDefId: string, editorSdk) =>
    toMonitored('editorApi.handleVerticalDeletion', async () => {
        const registeredMembersAreaAppMap: {[key: string]: IntegrationApplication[]} = getMembersAreaApps()
        const deletedVerticalApps: IntegrationApplication[] = registeredMembersAreaAppMap?.[verticalAppDefId] ?? []
        const updatedAppMap = {...registeredMembersAreaAppMap}
        delete updatedAppMap[verticalAppDefId]
        const updatedApps = [].concat(...Object.values(updatedAppMap))
        const hasNoDependencies = (app) => !updatedApps.some((app2) => app2.pageId === app.pageId)
        const isNotAppSection = (app) => app.method !== 'addSection'
        const appsWithoutDependencies = deletedVerticalApps.filter(hasNoDependencies).filter(isNotAppSection)

        await Promise.all(appsWithoutDependencies.map((app) => removePage(app.pageId, editorSdk)))

        setMembersAreaApps(updatedAppMap)
    })
