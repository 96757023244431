import webBiLogger from '@wix/web-bi-logger'
import initSchemaLogger from '@wix/bi-logger-members-app-users'
import {SANTA_MEMBERS_APP_ID} from '../editor-app-module/constants'

const createBIService = ({instanceId, biToken}) => {
    const baseProps = {app_id: SANTA_MEMBERS_APP_ID, instance_id: instanceId, biToken}
    const logger = initSchemaLogger(webBiLogger)()

    const logMembersMenuToggle = ({pageId, pageName, toggle_status, is_social}) => {
        const toggleName = 'Members Menu'
        const props = Object.assign({}, baseProps, {pageId, pageName, toggleName, toggle_status, is_social})
        logger.menuToggle(props)
    }

    const logLoginMenuToggle = ({pageId, pageName, toggle_status, is_social}) => {
        const toggleName = 'Login Menu'
        const props = Object.assign({}, baseProps, {pageId, pageName, toggleName, toggle_status, is_social})
        logger.menuToggle(props)
    }

    const logInstallationFailure = (errorMsg) => {
        const props = Object.assign({}, baseProps, {errorMsg, appInstanceId: baseProps.app_id})
        delete props.app_id
        logger.membersAreaInstallationError(props)
    }

    return {
        logMembersMenuToggle,
        logLoginMenuToggle,
        logInstallationFailure
    }
}

export {createBIService}
